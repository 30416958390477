const dpr = window.devicePixelRatio || 1
const canvas = document.getElementById('canvas')
const context = canvas.getContext('2d')
let canvasWidth = canvas.getBoundingClientRect().width
let canvasHeight = canvas.getBoundingClientRect().height

let groups = []

let offsetX
let offsetY
let dragInterval
let isMouseDown = false

let LINE_WIDTH_BASE
let LINE_WIDTH_LARGE
let NUM_SQUARES
let SCALE_FACTOR

class Square {
  constructor(x, y, size) {
    this.x = x
    this.y = y
    this.initialSize = Math.floor(size)
    this.size = Math.floor(size)
    this.lineWidth = LINE_WIDTH_BASE
    this.targetSize = Math.floor(size)
    this.targetLineWidth = LINE_WIDTH_BASE
    this.inHoverRadius = false
    this.color = document.documentElement.dataset.color
  }

  update() {
    const sizeOffset = Math.abs(this.initialSize - this.size) / 2

    context.translate(sizeOffset, sizeOffset)
    context.beginPath()
    context.lineWidth = this.lineWidth
    context.strokeStyle = `rgb(${this.color})`
    context.rect(this.x, this.y, this.size, this.size)
    context.stroke()
    context.translate(-sizeOffset, -sizeOffset)

    this.decay()

    if (this.inHoverRadius) {
      this.targetLineWidth = LINE_WIDTH_LARGE
    }
  }

  decay() {
    if (this.lineWidth >= LINE_WIDTH_LARGE) {
      this.targetLineWidth = LINE_WIDTH_BASE
    }

    if (this.targetLineWidth > this.lineWidth) {
      this.lineWidth += 0.2
    }

    if (this.targetLineWidth < this.lineWidth) {
      this.lineWidth -= 0.15
    }

    if (this.size <= this.initialSize * SCALE_FACTOR) {
      this.targetSize = this.initialSize
    }

    if (this.targetSize > this.size) {
      this.size += 0.2
    }

    if (this.targetSize < this.size) {
      this.size -= 0.5
    }

    if (Math.abs(this.lineWidth - LINE_WIDTH_BASE) < 0.2) {
      this.lineWidth = LINE_WIDTH_BASE
    }

    if (Math.abs(this.size - this.initialSize) < 0.5) {
      this.size = this.initialSize
    }
  }
}

class Group {
  constructor(centerX, centerY, squareSize) {
    this.size = squareSize * NUM_SQUARES
    this.x = centerX - this.size / 2
    this.y = centerY - this.size / 2
    this.centerX = centerX
    this.centerY = centerY
    this.squareSize = squareSize
    this.squares = []
    this.ripples = []
    this.drawSquares()
  }

  drawSquares() {
    for (let i = 1; i <= NUM_SQUARES; i++) {
      const squareSize = i * this.squareSize
      this.drawSquare(this.centerX - squareSize / 2, this.centerY - squareSize / 2, squareSize)
    }
  }

  drawSquare(x, y, size) {
    const square = new Square(x, y, size)
    this.squares.push(square)
  }

  addRipple(x, y) {
    for (let i = 0; i < this.squares.length; i++) {
      const square = this.squares[i]

      if (x >= square.x && x <= square.x + square.size && y >= square.y && y <= square.y + square.size) {
        this.ripples.push({ lower: i, upper: i })
        break
      }
    }
  }

  update() {
    if (offsetX && offsetY) {
      for (let i = 0; i < this.squares.length; i++) {
        const square = this.squares[i]
        square.inHoverRadius = false
      }

      for (let i = 0; i < this.squares.length; i++) {
        const square = this.squares[i]

        if (
          offsetX >= square.x &&
          offsetX <= square.x + square.size &&
          offsetY >= square.y &&
          offsetY <= square.y + square.size
        ) {
          square.inHoverRadius = true
          break
        }
      }
    }

    for (let i = this.ripples.length - 1; i >= 0; i--) {
      const ripple = this.ripples[i]

      if (ripple.upper < this.squares.length) {
        const upperSquare = this.squares[ripple.upper]
        upperSquare.targetLineWidth = LINE_WIDTH_LARGE
        upperSquare.targetSize = upperSquare.initialSize * SCALE_FACTOR
      }

      if (ripple.lower >= 0) {
        const lowerSquare = this.squares[ripple.lower]
        lowerSquare.targetLineWidth = LINE_WIDTH_LARGE
        lowerSquare.targetSize = lowerSquare.initialSize * SCALE_FACTOR
      }

      ripple.upper += 1
      ripple.lower -= 1

      if (ripple.upper >= this.squares.length && ripple.lower < 0) {
        this.ripples.splice(i, 1)
      }
    }

    this.squares.forEach((el) => el.update())
  }
}

const setupCanvas = (canvas) => {
  const rect = canvas.getBoundingClientRect()
  canvas.width = rect.width * dpr
  canvas.height = rect.height * dpr

  const ctx = canvas.getContext('2d')
  ctx.scale(dpr, dpr)
}

const resize = () => {
  canvasWidth = canvas.getBoundingClientRect().width
  canvasHeight = canvas.getBoundingClientRect().height
  canvas.width = canvasWidth
  canvas.height = canvasHeight

  setupCanvas(canvas)
}

const drawGroup = (x, y, squareSize) => {
  const group = new Group(x, y, squareSize)
  groups.push(group)
}

const run = () => {
  const drawScene = () => {
    context.clearRect(0, 0, canvasWidth, canvasHeight)

    for (let i = 0; i < groups.length; i++) {
      groups[i].update()
    }

    window.requestAnimationFrame(drawScene)
  }

  window.requestAnimationFrame(drawScene)
}

const start = () => {
  LINE_WIDTH_BASE = 2
  LINE_WIDTH_LARGE = 4
  NUM_SQUARES = 30
  SCALE_FACTOR = 0.98

  if (window.matchMedia('(min-width: 768px)').matches) {
    LINE_WIDTH_BASE = 4
    LINE_WIDTH_LARGE = 8
    NUM_SQUARES = 30
    SCALE_FACTOR = 0.98
  }

  groups = []

  const squareSize = (canvas.height / dpr - LINE_WIDTH_BASE * 2) / NUM_SQUARES

  let centerX = canvas.width / dpr / 2
  const centerY = canvas.height / dpr / 2

  // Center square
  drawGroup(centerX, centerY, squareSize)

  // Left Square
  centerX = canvas.width / dpr / 2 - squareSize * NUM_SQUARES - squareSize
  drawGroup(centerX, centerY, squareSize)

  // Right Square
  centerX = canvas.width / dpr / 2 + squareSize * NUM_SQUARES + squareSize
  drawGroup(centerX, centerY, squareSize)
}

const init = () => {
  setupCanvas(canvas)
  start()
  run()

  const createRipples = (e) => {
    const x = e.offsetX
    const y = e.offsetY

    groups.forEach((group) => {
      if (x >= group.x && x <= group.x + group.size && y >= group.y && y <= group.y + group.size) {
        group.addRipple(x, y)
      }
    })
  }

  window.addEventListener('resize', () => {
    resize()
    start()
  })

  canvas.addEventListener(
    'mousemove',
    (e) => {
      offsetX = e.offsetX
      offsetY = e.offsetY

      if (isMouseDown && !dragInterval) {
        createRipples(e)

        dragInterval = setInterval(() => {
          createRipples({ offsetX, offsetY })
        }, 250)
      }
    },
    false
  )

  canvas.addEventListener(
    'mouseout',
    () => {
      offsetX = null
      offsetY = null
    },
    false
  )

  canvas.addEventListener('mousedown', () => {
    isMouseDown = true
  })

  canvas.addEventListener(
    'mouseup',
    (e) => {
      isMouseDown = false
      clearInterval(dragInterval)
      dragInterval = null
      createRipples(e)
    },
    false
  )
}

export default {
  init,
}
