const canvas = document.getElementById('canvas')
const context = canvas.getContext('2d')
let canvasWidth = canvas.getBoundingClientRect().width
let canvasHeight = canvas.getBoundingClientRect().height

let squares = []
let squares2d = []
let outerRipples = []
let innerRipples = []

let offsetX
let offsetY
let dragInterval
let isMouseDown = false

let GRID_CELL
let LINE_WIDTH_BASE
let LINE_WIDTH_LARGE
let SQUARE_SIZE_BASE
let SQUARE_SIZE_LARGE
let SQUARE_SIZE_SMALL

class Square {
  constructor(x, y, size) {
    this.x = x
    this.y = y
    this.size = size
    this.targetSize = size
    this.opacity = 1
    this.fillOpacity = 0
    this.inHoverRadius = false
    this.inInnerRippleRadius = false
    this.inOuterRippleRadius = false
    this.color = document.documentElement.dataset.color
  }

  update() {
    let lineWidth = LINE_WIDTH_BASE

    if (this.inOuterRippleRadius) {
      lineWidth = LINE_WIDTH_LARGE
    }

    if (this.inHoverRadius) {
      this.fillOpacity = 1
    }

    context.beginPath()
    context.strokeStyle = `rgba(${this.color}, ${this.opacity})`
    context.fillStyle = `rgba(${this.color}, ${this.fillOpacity})`
    context.lineWidth = lineWidth

    context.fillRect(
      this.x - this.size / 2 - GRID_CELL / 2 - lineWidth / 2,
      this.y - this.size / 2 - GRID_CELL / 2 - lineWidth / 2,
      this.size + lineWidth,
      this.size + lineWidth
    )

    context.rect(this.x - this.size / 2 - GRID_CELL / 2, this.y - this.size / 2 - GRID_CELL / 2, this.size, this.size)
    context.stroke()

    this.checkHoverProximity()
    this.checkRippleProximity()
    this.decay()
  }

  checkHoverProximity() {
    let distance = SQUARE_SIZE_BASE

    if (offsetX && offsetY) {
      let dX = this.x - offsetX - GRID_CELL / 2
      let dY = this.y - offsetY - GRID_CELL / 2

      distance = Math.hypot(dX, dY)

      if (distance >= SQUARE_SIZE_BASE) {
        this.inHoverRadius = false
      } else {
        this.inHoverRadius = true
      }
    } else {
      this.inHoverRadius = false
    }
  }

  checkRippleProximity() {
    const centerX = this.x - this.size / 2
    const centerY = this.y - this.size / 2

    let inInnerRippleRadius = false
    let inOuterRippleRadius = false

    for (let i = outerRipples.length - 1; i >= 0; i--) {
      const distance = Math.abs(Math.hypot(centerX - outerRipples[i].x, centerY - outerRipples[i].y))

      const diff = Math.abs(distance - outerRipples[i].r)

      if (diff < SQUARE_SIZE_BASE * 2) {
        inOuterRippleRadius = true
        break
      }
    }

    for (let i = innerRipples.length - 1; i >= 0; i--) {
      const distance = Math.abs(Math.hypot(centerX - innerRipples[i].x, centerY - innerRipples[i].y))

      const diff = Math.abs(distance - innerRipples[i].r)

      if (diff < SQUARE_SIZE_BASE) {
        inInnerRippleRadius = true
        break
      }
    }

    if (inInnerRippleRadius) {
      this.targetSize = SQUARE_SIZE_SMALL
      this.opacity = 0
    }

    if (inOuterRippleRadius) {
      this.targetSize = SQUARE_SIZE_LARGE
    }

    this.inInnerRippleRadius = inInnerRippleRadius
    this.inOuterRippleRadius = inOuterRippleRadius
  }

  decay() {
    if (this.targetSize > this.size) {
      this.size += 0.6
    }

    if (this.targetSize < this.size) {
      this.size -= 0.6
    }

    if (this.size < SQUARE_SIZE_SMALL) {
      this.targetSize = SQUARE_SIZE_BASE
    }

    if (this.size > SQUARE_SIZE_LARGE) {
      this.targetSize = SQUARE_SIZE_BASE
    }

    if (this.opacity < 1) {
      this.opacity += 0.3
    }

    this.fillOpacity -= 0.025
  }
}

class Ripple {
  constructor(x, y, r) {
    this.x = x
    this.y = y
    this.r = r
  }

  update() {
    this.grow()
  }

  grow() {
    this.r += 5
  }
}

const setupCanvas = (canvas) => {
  const dpr = window.devicePixelRatio || 1
  const rect = canvas.getBoundingClientRect()
  canvas.width = rect.width * dpr
  canvas.height = rect.height * dpr

  const ctx = canvas.getContext('2d')
  ctx.scale(dpr, dpr)
}

const resize = () => {
  canvasWidth = canvas.getBoundingClientRect().width
  canvasHeight = canvas.getBoundingClientRect().height
  canvas.width = canvasWidth
  canvas.height = canvasHeight

  setupCanvas(canvas)
}

const drawSquare = (x, y, size) => {
  const square = new Square(x, y, size)
  squares.push(square)

  if (squares2d[x]) {
    squares2d[x][y] = square
  } else {
    squares2d[x] = []
  }
}

const run = () => {
  const drawScene = () => {
    context.clearRect(0, 0, canvasWidth, canvasHeight)

    for (let i = 0; i < squares.length; i++) {
      squares[i].update()
    }

    for (let i = 0; i < innerRipples.length; i++) {
      innerRipples[i].update()

      if (innerRipples[i].r > canvasWidth * 2) {
        innerRipples.splice(i, 1)
      }
    }

    for (let i = 0; i < outerRipples.length; i++) {
      outerRipples[i].update()

      if (outerRipples[i].r > canvasWidth * 2) {
        outerRipples.splice(i, 1)
      }
    }

    window.requestAnimationFrame(drawScene)
  }

  window.requestAnimationFrame(drawScene)
}

const start = () => {
  GRID_CELL = 20
  SQUARE_SIZE_BASE = 10
  SQUARE_SIZE_LARGE = 25
  SQUARE_SIZE_SMALL = 3
  LINE_WIDTH_BASE = 2
  LINE_WIDTH_LARGE = 4

  if (window.matchMedia('(min-width: 768px)').matches) {
    GRID_CELL = 40
    LINE_WIDTH_BASE = 4
    LINE_WIDTH_LARGE = 6
    SQUARE_SIZE_BASE = 20
    SQUARE_SIZE_LARGE = 35
    SQUARE_SIZE_SMALL = 5
  }

  const widthNum = Math.floor(canvasWidth / (GRID_CELL + 1))
  const remainder = canvasWidth % (GRID_CELL + 1)
  const extraOffset = remainder / widthNum

  GRID_CELL += extraOffset

  squares = []
  squares2d = []

  for (let y = GRID_CELL; y <= canvasHeight; y += GRID_CELL + 1) {
    for (let x = GRID_CELL; x <= canvasWidth; x += GRID_CELL + 1) {
      drawSquare(x, y, SQUARE_SIZE_BASE)
    }
  }
}

const init = () => {
  setupCanvas(canvas)
  start()
  run()

  const createRipples = (e) => {
    const outerRipple = new Ripple(e.offsetX, e.offsetY, GRID_CELL * 4)
    outerRipples.push(outerRipple)

    const innerRipple = new Ripple(e.offsetX, e.offsetY, GRID_CELL)
    innerRipples.push(innerRipple)
  }

  window.addEventListener('resize', () => {
    resize()
    start()
  })

  canvas.addEventListener(
    'mousemove',
    (e) => {
      offsetX = e.offsetX
      offsetY = e.offsetY

      if (isMouseDown && !dragInterval) {
        createRipples(e)

        dragInterval = setInterval(() => {
          createRipples({ offsetX, offsetY })
        }, 250)
      }
    },
    false
  )

  canvas.addEventListener(
    'mouseout',
    () => {
      offsetX = null
      offsetY = null
    },
    false
  )

  canvas.addEventListener('mousedown', () => {
    isMouseDown = true
  })

  canvas.addEventListener(
    'mouseup',
    (e) => {
      isMouseDown = false
      clearInterval(dragInterval)
      dragInterval = null
      createRipples(e)
    },
    false
  )
}

export default {
  init,
}
