const dpr = window.devicePixelRatio || 1
const canvas = document.getElementById('canvas')
const context = canvas.getContext('2d')
let canvasWidth = canvas.getBoundingClientRect().width
let canvasHeight = canvas.getBoundingClientRect().height

let squares = []
let ripples = []

let offsetX
let offsetY
let dragInterval
let isMouseDown = false

let LINE_WIDTH_BASE
let LINE_WIDTH_LARGE
let NUM_SQUARES
let SCALE_FACTOR

const distance = (p1, p2) => {
  return Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2))
}

const triangleArea = (d1, d2, d3) => {
  const s = (d1 + d2 + d3) / 2
  return Math.sqrt(s * (s - d1) * (s - d2) * (s - d3))
}

const rotatePoint = (point, rotationCenterPoint, degrees) => {
  const radians = (degrees * Math.PI) / 180
  point[0] -= rotationCenterPoint[0]
  point[1] -= rotationCenterPoint[1]

  const newPoint = []
  newPoint[0] = point[0] * Math.cos(radians) - point[1] * Math.sin(radians)
  newPoint[1] = point[0] * Math.sin(radians) + point[1] * Math.cos(radians)

  newPoint[0] += rotationCenterPoint[0]
  newPoint[1] += rotationCenterPoint[1]

  return newPoint
}

class Square {
  constructor(x, y, size, deg) {
    this.x = x
    this.y = y
    this.initialSize = Math.floor(size)
    this.size = Math.floor(size)
    this.deg = deg
    this.vertices = this.setVertices()
    this.lineWidth = LINE_WIDTH_BASE
    this.targetLineWidth = LINE_WIDTH_BASE
    this.targetSize = Math.floor(size)
    this.inHoverRadius = false
    this.color = document.documentElement.dataset.color
  }

  setVertices() {
    const left = this.x
    const right = this.x + this.size
    const top = this.y
    const bottom = this.y + this.size

    const center = [this.x + this.size / 2, this.y + this.size / 2]
    const LT = [left, top]
    const RT = [right, top]
    const RB = [right, bottom]
    const LB = [left, bottom]

    return {
      LT: rotatePoint(LT, center, this.deg),
      RT: rotatePoint(RT, center, this.deg),
      RB: rotatePoint(RB, center, this.deg),
      LB: rotatePoint(LB, center, this.deg),
    }
  }

  update() {
    const sizeOffset = Math.abs(this.initialSize - this.size) / 2
    const transX = this.x + this.size / 2
    const transY = this.y + this.size / 2

    context.save()
    context.translate(transX, transY)
    context.rotate(this.deg * (Math.PI / 180))
    context.translate(-transX, -transY)

    context.translate(sizeOffset, sizeOffset)
    context.beginPath()
    context.lineWidth = this.lineWidth
    context.strokeStyle = `rgb(${this.color})`
    context.rect(this.x, this.y, this.size, this.size)
    context.stroke()
    context.translate(-sizeOffset, -sizeOffset)
    context.restore()

    if (this.inHoverRadius) {
      this.targetLineWidth = LINE_WIDTH_LARGE
    }

    this.decay()
  }

  decay() {
    if (this.lineWidth >= LINE_WIDTH_LARGE) {
      this.targetLineWidth = LINE_WIDTH_BASE
    }

    if (this.targetLineWidth > this.lineWidth) {
      this.lineWidth += 0.5
    }

    if (this.targetLineWidth < this.lineWidth) {
      this.lineWidth -= 0.2
    }

    if (this.size <= this.initialSize * SCALE_FACTOR) {
      this.targetSize = this.initialSize
    }

    if (this.targetSize > this.size) {
      this.size += 1.5
    }

    if (this.targetSize < this.size) {
      this.size -= 1
    }

    if (Math.abs(this.lineWidth - LINE_WIDTH_BASE) < 0.3) {
      this.lineWidth = LINE_WIDTH_BASE
    }

    if (Math.abs(this.size - this.initialSize) < 0.5) {
      this.size = this.initialSize
    }
  }
}

const setupCanvas = (canvas) => {
  const rect = canvas.getBoundingClientRect()
  canvas.width = rect.width * dpr
  canvas.height = rect.height * dpr

  const ctx = canvas.getContext('2d')
  ctx.scale(dpr, dpr)
}

const resize = () => {
  canvasWidth = canvas.getBoundingClientRect().width
  canvasHeight = canvas.getBoundingClientRect().height
  canvas.width = canvasWidth
  canvas.height = canvasHeight

  setupCanvas(canvas)
}

const drawSquare = (x, y, size, deg) => {
  const square = new Square(x, y, size, deg)
  squares.push(square)
}

const run = () => {
  const drawScene = () => {
    context.clearRect(0, 0, canvasWidth, canvasHeight)

    if (offsetX && offsetY) {
      for (let i = 0; i < squares.length; i++) {
        const square = squares[i]
        square.inHoverRadius = false
      }

      for (let i = squares.length - 1; i >= 0; i--) {
        const square = squares[i]
        const vertices = square.vertices

        const click = [offsetX, offsetY]

        const rectArea = Math.round(square.size ** 2)

        let triArea = [
          triangleArea(distance(click, vertices.LT), distance(vertices.LT, vertices.RT), distance(vertices.RT, click)),
          triangleArea(distance(click, vertices.RT), distance(vertices.RT, vertices.RB), distance(vertices.RB, click)),
          triangleArea(distance(click, vertices.RB), distance(vertices.RB, vertices.LB), distance(vertices.LB, click)),
          triangleArea(distance(click, vertices.LB), distance(vertices.LB, vertices.LT), distance(vertices.LT, click)),
        ]

        triArea = Math.round(
          triArea.reduce(function (a, b) {
            return a + b
          }, 0)
        )

        if (triArea <= rectArea) {
          square.inHoverRadius = true
          break
        }
      }
    }

    for (let i = ripples.length - 1; i >= 0; i--) {
      const ripple = ripples[i]

      if (ripple.upper < squares.length) {
        const upperSquare = squares[ripple.upper]
        upperSquare.targetLineWidth = LINE_WIDTH_LARGE
        upperSquare.targetSize = upperSquare.initialSize * SCALE_FACTOR
      }

      if (ripple.lower >= 0) {
        const lowerSquare = squares[ripple.lower]
        lowerSquare.targetLineWidth = LINE_WIDTH_LARGE
        lowerSquare.targetSize = lowerSquare.initialSize * SCALE_FACTOR
      }

      ripple.upper += 1
      ripple.lower -= 1

      if (ripple.upper >= squares.length && ripple.lower < 0) {
        ripples.splice(i, 1)
      }
    }

    for (let i = 0; i < squares.length; i++) {
      squares[i].update()
    }

    window.requestAnimationFrame(drawScene)
  }

  window.requestAnimationFrame(drawScene)
}

const start = () => {
  LINE_WIDTH_BASE = 2
  LINE_WIDTH_LARGE = 4
  NUM_SQUARES = 31
  SCALE_FACTOR = 0.96

  if (window.matchMedia('(min-width: 768px)').matches) {
    LINE_WIDTH_BASE = 4
    LINE_WIDTH_LARGE = 10
    NUM_SQUARES = 41
    SCALE_FACTOR = 0.98
  }

  squares = []

  const squareSize = canvas.height / dpr - LINE_WIDTH_LARGE * 2
  const distanceBetween = canvas.width / dpr / NUM_SQUARES

  for (let i = 0; i < NUM_SQUARES; i++) {
    const deg = i * 1.5
    const degRad = deg * (Math.PI / 180)
    const x = canvas.width / dpr - i * distanceBetween - squareSize / 2
    const scaledSize = squareSize / (Math.cos(degRad) + Math.sin(degRad))
    const center = [x + scaledSize / 2, scaledSize / 2]
    const rotatedOrigin = rotatePoint([x, 0], center, deg)
    const y = Math.abs(rotatedOrigin[1]) + LINE_WIDTH_LARGE

    drawSquare(x, y, scaledSize, deg)
  }
}

const init = () => {
  setupCanvas(canvas)
  start()
  run()

  const createRipples = (e) => {
    const x = e.offsetX
    const y = e.offsetY

    for (let i = squares.length - 1; i >= 0; i--) {
      const square = squares[i]
      const vertices = square.vertices

      const click = [x, y]

      const rectArea = Math.round(square.size ** 2)

      let triArea = [
        triangleArea(distance(click, vertices.LT), distance(vertices.LT, vertices.RT), distance(vertices.RT, click)),
        triangleArea(distance(click, vertices.RT), distance(vertices.RT, vertices.RB), distance(vertices.RB, click)),
        triangleArea(distance(click, vertices.RB), distance(vertices.RB, vertices.LB), distance(vertices.LB, click)),
        triangleArea(distance(click, vertices.LB), distance(vertices.LB, vertices.LT), distance(vertices.LT, click)),
      ]

      triArea = Math.round(
        triArea.reduce(function (a, b) {
          return a + b
        }, 0)
      )

      if (triArea <= rectArea) {
        ripples.push({ lower: i, upper: i })
        break
      }
    }
  }

  window.addEventListener('resize', () => {
    resize()
    start()
  })

  canvas.addEventListener(
    'mousemove',
    (e) => {
      offsetX = e.offsetX
      offsetY = e.offsetY

      if (isMouseDown && !dragInterval) {
        createRipples(e)

        dragInterval = setInterval(() => {
          createRipples({ offsetX, offsetY })
        }, 250)
      }
    },
    false
  )

  canvas.addEventListener(
    'mouseout',
    () => {
      offsetX = null
      offsetY = null
    },
    false
  )

  canvas.addEventListener('mousedown', () => {
    isMouseDown = true
  })

  canvas.addEventListener(
    'mouseup',
    (e) => {
      isMouseDown = false
      clearInterval(dragInterval)
      dragInterval = null
      createRipples(e)
    },
    false
  )
}

export default {
  init,
}
