import Grid from './grid'
import Concentric from './concentric'
import Rotated from './rotated'

const views = ['grid', 'concentric', 'rotated']
const colors = ['104, 68, 193', '227, 132, 23', '57, 127, 87']
const randomColor = colors[Math.floor(Math.random() * colors.length)]

const setupCanvas = () => {
  let selectedView
  const urlParams = new URLSearchParams(window.location.search)
  const urlView = urlParams.get('view')
  const isDarkMode = urlParams.get('dark')

  if (isDarkMode) {
    document.documentElement.classList.add('is-dark')
  }

  if (views.indexOf(urlView) > -1) {
    selectedView = urlView
  } else {
    selectedView = views[Math.floor(Math.random() * views.length)]
  }

  if (selectedView === 'grid') {
    document.documentElement.classList.add('as-grid')
    Grid.init()
  }

  if (selectedView === 'concentric') {
    document.documentElement.classList.add('as-concentric')
    Concentric.init()
  }

  if (selectedView === 'rotated') {
    document.documentElement.classList.add('as-rotated')
    Rotated.init()
  }
}

const init = () => {
  document.documentElement.style.setProperty('--body-color', `rgb(${randomColor})`)
  document.documentElement.dataset.color = randomColor

  setupCanvas()
}

init()
